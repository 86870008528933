.help-inputbox {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  filter: drop-shadow(0.391px 0.921px 1.5px rgba(0,0,0,0.1));
  background: #fff;
  border-radius: 0 0 6px 6px;
  border: 1px solid #e9ebec;
  z-index: 3;
  height: 450px;

  &.on {
    display: block;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    height: 450px;
    transition: 0.2s ease-out;
  }

  &.off {
    display: none;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    height: 0px;
    transition: 0.2s ease-out;
  }

  .h5 {
    position: relative;
    font-size: 14px;
    color: #6d7080;
    padding: 20px 20px 0;
    .btn {
      position: absolute;
      top: 12px;
      right: 20px;
      padding: 0;
      color: #6d7080;
    }
  }
  ul:last-child {
    min-height: 170px;
    max-height: 300px;
    overflow-y: hidden;
    &.off {
      max-height: 170px;
    }
  }

  a.search {
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    color: #222;
    line-height: 37px;
    padding: 0px 20px;
    cursor: pointer;
    height: 37px;

    &:hover {
      background: #f3f6f9;
    }

    &:focus {
      background: #f3f6f9;
    }

    .searchText {
      line-height: 37px;
      width: 70%;
      float: left;
      background-color: transparent;
    }

    .search-date {
      line-height: 37px;
      margin-right: 10px;
      font-size: 13px;
      color: #888;
    }

    i {
      color: #6d7080;
      margin-right: 5px;
      vertical-align: middle;
      font-size: 18px;
      &.fr {
        line-height: 37px;
        margin: 0;
      }
    }
  }
  ul {
    padding: 10px 0;
    li {
      font-size: 14px;
      color: #222;
      line-height: 37px;
      padding: 0px 20px;
      cursor: pointer;
      height: 37px;

      &:hover {
        background: #f3f6f9;
      }

      .searchText {
        line-height: 37px;
        width: 70%;
        float: left;
        background-color: transparent;
      }

      .search-date {
        line-height: 37px;
        margin-right: 10px;
        font-size: 13px;
        color: #888;
      }

      i {
        color: #6d7080;
        margin-right: 5px;
        vertical-align: middle;
        font-size: 18px;
        &.fr {
          line-height: 37px;
          margin: 0;
        }
      }
    }
  }
}

.bb{
  border-bottom: 1px solid #e9ebec;
}