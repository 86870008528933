
/* color */
:root {
	--white-color: #fff;

	--main-color: #03c75b;
	--main-color-hover: #01936c;
	--main-color-disable: #dde0e5;

	--sub-color: #434e54;
	--sub-color-hover: #7a838a;
	--sub-color-disable: #dde0e5;

	--main-color-line: #03c75b;
	--main-color-line-hover: #ebf6ee;
	--main-color-line-disable: #fff;

	--main-color-error: #d60000;
	--main-color-error-hover: #ffdada;
	--main-color-error-disable: #dde0e5;

	--line-color: #03c75a;
	--line-color-hover: #ebf7ee;

	--line-color-black: #434e54;
	--line-color-black-hover: #f7f7f7;

	--line-color-grey: #ccc;
	--line-color-grey-hover: #f7f7f7;

	--line-color-light-grey: #dedede;
	--line-color-light-grey-hover: #f7f7f7;

	--font-color-main: #03c75a;
	--font-color-black: #222;
	--font-color-grey: #777;
	--font-color-light-grey: #999;

	--swiper-pagination-color: #03c75b;
}


ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
  text-wrap: nowrap;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #222;
}
a:hover {
  text-decoration: none;
}
p {
  padding: 0;
  margin: 0;
}
table {
  width: 100%;
  border-collapse:collapse;
}

.flex {
  display: flex
}
.flex.vcenter {
  align-items: center;
}
.flex.between {
  justify-content: space-between;
}
.flex.center {
  justify-content: center;
}
.flex.space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.normal {font-weight: normal !important;}
.bold {font-weight: bold !important;}
.vt {vertical-align: top !important;}
.vs {vertical-align: sub !important;}
.vm {vertical-align: middle !important;}
.fl {float: left;}
.fr {float: right;}
.cp {cursor: pointer;}
.left {text-align: left !important;}
.right {text-align: right !important;}
.text-right {text-align: right !important;}
.center {text-align: center !important;}
.hidden {display: none !important;}
.displayBlock {display: block;}

.w40per {width: 40%;}
.w50per {width: 50%;}
.w60per {width: 60%;}

.f10 {
  font-size: 10px !important;

  * {
    font-size: 10px !important;
  }
}
.f11 {font-size: 11px !important;}
.f12 {font-size: 12px !important;}
.f13 {font-size: 13px !important;}
.f14 {font-size: 14px !important;}
.f15 {font-size: 15px !important;}
.f16 {font-size: 16px !important;}
.f18 {font-size: 18px !important;}

.h100 {display: inline-block; width: 100%; height: 100%;}

.p0 {padding:0 !important}
.p5 {padding: 5px !important;}
.p10 {padding:10px !important;}
.p20 {padding:20px !important;}
.p30 {padding:30px !important;}
.p40 {padding:40px !important;}

.pt0 {padding-top: 0px !important;}
.pt2 {padding-top: 2px !important;}
.pt3 {padding-top: 3px !important;}
.pt5 {padding-top: 5px !important;}
.pt10 {padding-top: 10px !important;}
.pt15 {padding-top: 15px !important;}
.pt20 {padding-top: 20px !important;}
.pt30 {padding-top: 30px !important;}
.pt40 {padding-top: 40px !important;}

.pl0 {padding-left:0px !important;}
.pl5 {padding-left: 5px !important;}
.pl10 {padding-left: 10px !important;}
.pl20 {padding-left:20px !important;}
.pl30 {padding-left:30px !important;}
.pl40 {padding-left:40px !important;}
.pl50 {padding-left: 50px !important;}

.pr5 {padding-right: 5px !important;}
.pr10 {padding-right: 10px !important;}
.pr20 {padding-right: 20px !important;}
.pr30 {padding-right: 30px !important;}
.pr40 {padding-right: 40px !important;}

.pb0 {padding-bottom: 0px !important;}
.pb5 {padding-bottom: 5px !important;}
.pb10 {padding-bottom: 10px !important;}
.pb15 {padding-bottom: 15px !important;}
.pb20 {padding-bottom: 20px !important;}
.pb25 {padding-bottom: 25px !important;}
.pb30 {padding-bottom: 30px !important;}
.pb40 {padding-bottom: 40px !important;}

.m0 {margin:0}
.m10 {margin:10px}
.m20 {margin:20px}

.mt0 {margin-top: 0px !important;}
.mt2 {margin-top: 2px !important;}
.mt3 {margin-top: 3px !important;}
.mt5 {margin-top: 5px !important;}
.mt7 {margin-top: 7px !important;}
.mt10 {margin-top: 10px !important;}
.mt15 {margin-top: 15px !important;}
.mt20 {margin-top: 20px !important;}
.mt30 {margin-top: 30px !important;}
.mt33 {margin-top: 33px !important;}
.mt35 {margin-top: 35px !important;}
.mt40 {margin-top: 40px !important;}

.mb0 {margin-bottom: 0px !important;}
.mb3 {margin-bottom: 3px !important;}
.mb5 {margin-bottom: 5px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb30 {margin-bottom: 30px !important;}
.mb40 {margin-bottom: 40px !important;}
.mb80 {margin-bottom: 80px !important;}

.ml0 {margin-left:0px !important;}
.ml2 {margin-left: 2px !important;}
.ml3 {margin-left: 3px !important;}
.ml4 {margin-left: 4px !important;}
.ml5 {margin-left: 5px !important;}
.ml6 {margin-left: 6px !important;}
.ml10 {margin-left: 10px !important;}
.ml20 {margin-left: 20px !important;}
.ml30 {margin-left:30px !important;}
.ml40 {margin-left:40px !important;}

.mr0 {margin-right:0px !important;}
.mr3 {margin-right: 3px !important;}
.mr4 {margin-right: 4px !important;}
.mr5 {margin-right: 5px !important;}
.mr6 {margin-right: 6px !important;}
.mr10 {margin-right: 10px !important;}
.mr20 {margin-right: 20px !important;}
.mr30 {margin-right:30px !important;}
.mr40 {margin-right:40px !important;}
.mr50 {margin-right: 50px !important;}

.cred {color: red !important;}
.cblue {color: blue !important;}
.clightgrey {color: #888;}

.readonly {background-color: #eee;}

.error {background-color: red; color: #fff !important;}

/* color */
.color-green {color: #45cb85;}
.color-lblue {color: #299cdb;}
.color-yellow {color: #ffbe0b;}
.color-red {color: #f06548;}
.color-blue {color: #3577f1;}

.bg-green {background-color: #45cb85;}
.bg-lblue {background-color: #299cdb;}
.bg-yellow {background-color: #ffbe0b;}
.bg-red {background-color: #f06548;}
.bg-blue {background-color: #3577f1;}
.bg-blue1 {background-color: #4b38b3;}

.outline-green {color: #1cb766; border-color: #4dc17f;}
.outline-lblue {color: #299cdb; border-color: #299cdb;}
.outline-yellow {color: #ffbe0b; border-color: #ffbe0b;}
.outline-red {color: #f06548; border-color: #f06548;}
.outline-blue {color: #3577f1; border-color: #3577f1;}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index:1099;

  .modal-wrap {
    height: auto;
    max-height: 100%;
    transform: translate(0);
    background-color: #fff;
    border-radius: 8px;
    .modal-header {
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #e9ecef;
      border-radius: 8px 8px 0 0;
      padding: 0 20px;
      .modal-title {
        float: left;
        font-size: 18px;
        font-weight: 500;
        color: #636363;
        line-height: 60px;
      }
      .close-btn {
        float: right;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        margin-top: 18px;
        font-size: 28px;
        color: #636363;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
        .MuiSvgIcon-root {
          font-size: 28px;
        }
      }
    }
  }
}
.modal-wrap .modal-body {
  font-size: 16px;
  padding: 10px;
}
.modal-wrap .modal-footer {
  padding: 10px 20px;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  text-align: right;
}

.modal-wrap1 .modal-body label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.modal-wrap .modal-body label .TextField-input.w100 {
  width: calc(100% - 104px);
}
.modal-wrap .modal-body label .TextField-input.MuiFormControl-root .MuiOutlinedInput-root {
  width: 100%;
}

/* tab */
.Tab-page {
  height: 100%;
  .tab-menu {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #e9ebec;
    li {
      float: left;
      font-size: 16px;
      font-weight: 500;
      color: #495057;
      line-height: 45px;
      text-align: center;
      width: calc(100% / 3);
      height: 45px;
      border: 1px solid transparent;
      border-bottom: 1px solid #e9ebec;
      border-radius: 4px 4px 0 0;
      background: #fff;
      cursor: pointer;

      &.active {
        border: 1px solid #e9ebec;
        border-top: 3px solid #4c38b5;
        border-bottom: 1px solid transparent;
        background: #f3f3f9;
      }
    }

    &.menu2 {
      li {
        width: calc(100% / 2);
      }
    }
    &.menu4 {
      li {
        width: calc(100% / 4);
      }
    }
    &.menu5 {
      li {
        width: calc(100% / 5);
      }
    }
    &.menu7 {
      li {
        width: calc(100% / 7);
      }
    }
  }
  .tab-contents {
    // padding: 20px 0 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    > div {
      height: 100%;
    }
  }
  .tab-contents::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
  }
}

/* table */
.table-input {
  margin: 15px 0;
  th {
    font-size: 14px;
    font-weight: 500;
    color: #636363;
    text-align: right;
    padding-right: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dee2e6;
  }
  td {
    font-size: 12px;
    font-weight: 500;
    color: #636363;
    line-height: 28px;
    padding: 5px;
    border: 1px solid #dee2e6;
  }
  .TextField-input.MuiTextField-root {
    width: 100%;
  }
}

.lh120 {
  line-height: 120%;
}
.lh150 {
  line-height: 150% !important;
}
.lh22px {
  line-height: 22px;
}
.lh28px {
  line-height: 28px;
}
.lh30px {
  line-height: 30px;
}
.lh35px {
  line-height: 35px;
}
.lh38px {
  line-height: 38px;
}
.lh39px {
  line-height: 39px;
}
.lh40px {
  line-height: 40px !important;
}
.lh45px {
  line-height: 45px;
}

.search-input {
  .MuiInputBase-root {
    /*border-radius: 3px !important;*/
  }

  input {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0px 15px !important;
    font-size: 13px;
  }
}

.search-toggle-btn {
  height: 28px !important;
  line-height: 28px !important;
  padding: 0px !important;
  font-size: 13px !important;
  vertical-align: bottom !important;
  font-family: "Pretendard", sans-serif !important;

  button {
    padding: 0px 10px !important;
  }
}

.search-input-btn {
  input {
    height: 36px !important;
    line-height: 34px !important;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}

.input-date {
  input {
    height: 40px !important;
    line-height: 34px !important;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}


/* scroll */
.scroll-y {
	overflow-y: auto;
	/* width */
	&::-webkit-scrollbar {
		width: 5px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: #fff;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #ccc;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #999;
	}
}

/* badge */
.badge-red {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: #f17171;
	margin-left: 5px;
	vertical-align: text-top;
}

.MuiInputAdornment-root {
  p {
    padding-top: 3px;
    font-size: 12px;
  }
}

.MuiInputLabel-shrink {
  padding-top: 6px !important;
}

.ellipsis2 {
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

iframe#webpack-dev-server-client-overlay{display:none!important}