.main-content, .user-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding: 70px 0 0 250px;
  background: #f2f2f7;
  overflow-y: auto;
  min-width: 1900px;

  .admin-cms-contents {
    background-color: #fff;
    height: calc(100vh - 70px);
    padding: 0px 10px 0px 10px;
    overflow-y: hidden;

    .admin-cms-search-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0px 0px 0px;

      .MuiOutlinedInput-input {
        height: 28px;
        line-height: 28px;
        padding: 0px 10px;
        font-size: 13px;
      }
    }

    .admin-cms-search-header.sub-header {
      padding: 10px 0px 0px 0px;
      border-bottom: solid 0px #ccc;
    }

    .admin-cms-search-header.left {
      justify-content: flex-start !important;
    }

    .admin-cms-body {
      margin-top: 10px;
      height: calc(100% - 50px);
      overflow-y: auto;
    }

    .admin-tab-body {
      margin-top: 10px;
    }

  }
}

.ag-theme-balham .f15 * {
  font-size: 15px;
}

.ag-header-cell-text {
  color: #666;
  text-align: center;
}

.input-table {
  border-collapse: collapse;

  th {
    background-color: #f3f6f9;
    font-size: 13px;
    color: #666;
    border: solid 1px #eee;
    font-weight: 500;
    text-align: center;
  }

  td {
    font-size: 13px;
    color: #333;
    border: solid 1px #eee;
    padding: 1px;

    .MuiOutlinedInput-input {
      padding: 0px 10px !important;
      min-height: 28px;
      line-height: 28px;
      font-size: 13px;
    }
  }

  td.topNoBorder,
  th.topNoBorder {
    border-top: solid 0px #fff !important;
  }

  .td-border td {
    border-color: #c7c3c3;
    background-color: #f2f2f2;
    text-align: right;
    height: 30px;
    font-weight: 500;
  }

  .td-border1 th {
    border: solid 1px #cecece;
    background-color: #f3f3f3;
    text-align: center;
    height: 31px;
    font-size: 12px;
    font-weight: bold;
  }

  .td-border1 td {
    border: solid 1px #cecece;
    text-align: center;
    height: 31px;
    font-size: 12px;
  }
}

.input-table.table-p5 {
  th, td {
    padding: 5px;
  }
}

.Resizer.vertical {
  width: 8px;
  cursor: col-resize;
  background: #fff url(/assets/images/icon_split.svg) no-repeat 50%;
  opacity: 1 !important;

  &:hover {
    background-color: #fff;
  }
}

.Resizer.horizontal {
  height: 8px;
  cursor: row-resize;
  background: #fff url(/assets/images/icon_split_horizontal.svg) no-repeat 50%;
  opacity: 1 !important;

  &:hover {
    background-color: #fff;
  }
}


/* 왼쪽메뉴 축소 */
.reduce {
 .main-content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 70px 0 0 70px;
    background: #f2f2f7;
  }
}

.user-content {
  padding: 70px 0 0px 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  .footer-txt {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color: #98a6ad;
    line-height: 60px;
    text-align: center;
  }
}


/* 페이지 안내 */
.pageInfoMsg {
  height: 100vh;
  background: #f2f2f7;
  .pageInfo-header {
    padding: 20px 30px;
  }
  .errorMsg {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 1470px;
    height: calc(100vh - 140px);
    margin: 0 auto;
  }
  /* 페이지 안내 - 오류 */
  .error1 {
    background: url(/assets/images/icon_error.png) no-repeat 50% 0;
    padding-top: 110px;
    .txt1 {
      font-size: 34px;
      font-weight: 300;
      color: #ef5e43;
      text-align: center;
    }
    .txt2 {
      font-size: 14px;
      line-height: 20px;
      color: #222;
      padding-top: 15px;
    }
  }
  /* 페이지 안내 - 페이지가 없습니다. */
  .error2 {
    background: url(/assets/images/icon_error1.png) no-repeat 50% 0;
    padding-top: 110px;
    .txt1 {
      font-size: 34px;
      font-weight: 300;
      color: #495057;
      text-align: center;
    }
  }
}


@media screen and (max-width:1024px) {
  .main-content, .user-content {
    padding: 70px 0 0 70px;
    min-width: 100%;

    .organization {
      font-size: 15px;
    }

    /* 설계비가산정 */
    .admin-cms-contents.cost-page {
     /* min-width: 1920px; */
    }
    /* 열람관리 */
    .admin-cms-contents.reading-page {
      /* min-width: 1920px; */
    }
    /* 로그조회 */
    .admin-cms-contents.log-page {
      /* min-width: 1920px; */
    }
    .admin-cms-contents.log-page.pad-1920 {
      min-width: 1920px;
    }
    /* 시스템관리 */
    .admin-cms-contents.system-page {
      /* min-width: 1920px; */
    }
    /* CMS관리 */
    .admin-cms-contents.cms-page {
      min-width: 1920px;
    }

    .admin-cms-contents {
      .admin-cms-search-header.pad-header {
        display: block;
        .right {
          margin-top: 5px;
        }
      }  
      .common-grid {
       /* height: calc(100vh - 163px) !important; */
      }
    }  
  }
  .user-content {
    padding: 70px 0 0px 0;
  }

  /* pad height 조정 */
  .pad-height165 {
    height: calc(100vh - 165px) !important;
  }  
  .pad-height175 {
    height: calc(100vh - 175px) !important;
  }
  .pad-height198 {
    height: calc(100vh - 198px) !important;
  }    
  .pad-height200 {
    height: calc(100vh - 200px) !important;
  }  
  .pad-height210 {
    height: calc(100vh - 210px) !important;
  }    
  .pad-height220 {
    height: calc(100vh - 220px) !important;
  }    
  .pad-height242 {
    height: calc(100vh - 242px) !important;
  }   
  .pad-height320 {
    height: calc(100vh - 320px) !important;
  }      
  .pad-height345 {
    height: calc(100vh - 345px) !important;
  }  
  .pad-height350 {
    height: calc(100vh - 350px) !important;
  }    
  .pad-height375 {
    height: calc(100vh - 375px) !important;
  }
  .pad-height380 {
    height: calc(100vh - 380px) !important;
  }
  .pad-height420 {
    height: calc(100vh - 420px) !important;
  }  
  .pad-height490 {
    height: calc(100vh - 490px) !important;
  }
  .pad-height500 {
    height: calc(100vh - 500px) !important;
  }  
  .pad-height45-percent {
    height: calc(100% - 45px) !important;
  }    
  .pad-height78-percent {
    height: calc(100% - 78px) !important;
  }  
  .pad-height90-percent {
    height: calc(100% - 90px) !important;
  }    
  .pad-height140-percent {
    height: calc(100% - 140px) !important;
  } 
  .pad-mt1 {
    margin-top: 1px !important;
  }       
  .pad-mt5 {
    margin-top: 5px !important;
  }
  .pad-mt28-minus {
    margin-top: -28px !important;
  }
  .pad-fl-newLine {
    float: none !important;
    clear: both;
    padding-top: 5px;
  }
  .pad-newLine {
    display: block;
    padding-top: 5px;
  }
}