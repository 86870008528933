@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

/* Pretendard */
@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Black.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-ExtraBold.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Bold.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-SemiBold.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Medium.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Regular.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Light.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-ExtraLight.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('assets/fonts/Pretendard-1.3.7/web/static/woff2/Pretendard-Thin.woff2') format('woff2'), url('assets/fonts/Pretendard-1.3.7/web/static/woff/Pretendard-Thin.woff') format('woff');
}




/* Inter */
/* @import url(/assets/fonts/Inter/Inter.css); */


/* Noto Sans KR (korean) */

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Thin.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Thin.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Light.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Light.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Regular.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Regular.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Medium.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Medium.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Bold.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Bold.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/NotoSansKR/NotoSansKR-Black.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Black.woff) format('woff'),
       url(assets/fonts/NotoSansKR/NotoSansKR-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'), url('assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Italic'), url('assets/fonts/Roboto/Roboto-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'), url('assets/fonts/Roboto/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold Italic'), url('assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Thin'), url('assets/fonts/Roboto/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Thin Italic'), url('assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Light'), url('assets/fonts/Roboto/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Light Italic'), url('assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Medium'), url('assets/fonts/Roboto/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Medium Italic'), url('assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black'), url('assets/fonts/Roboto/Roboto-Black.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black Italic'), url('assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
}