.total-search {
  .search-input {
    padding-top: 200px;
    .input-txt {
      font-size: 24px;
      font-weight: bold;
      color: #495057;
      letter-spacing: -1px;
      text-align: center;
      padding-bottom: 30px;
    }
    .searchBox1 {
      position: relative;
      width: 510px;
      margin: 0 auto;
      .search-input {
        height: 50px;
        border-radius: 6px;
        background-color: #fff;
        padding: 0 0px 0 0px;
        &:focus-visible {
          outline: none;
        }
      }
    }

    .searchBoxBlur  {
      width: 510px;
      margin: 0 auto;

      .searchBox {
        position: relative;
        width: 100%;
        border: 1px solid #cdd3d9;
        input {
          font-size: 16px;
          line-height: 50px !important;
          height: 50px !important;
          padding: 0px 15px !important;
        }
        fieldset {
          border: none;
        }
        .search-input {
          height: 50px;
          border-radius: 6px;
          background-color: #fff;
          padding: 0 30px 0 40px;
          &:focus-visible {
            outline: none;
          }
        }
        .search-widget-icon {
          color: #878a99;
          font-size: 22px;
          left: 13px;
          line-height: 50px;
          position: absolute;
          top: 0;
          z-index: 10;
          &.search-widget-icon-right {
            left: auto;
            right: 7px;
            cursor: pointer;
          }
        }
      }
      .MuiFormControlLabel-label {
        font-size: 14px;
      }
    }
  }
}

.total-search.detail {
  .search-input {
    padding-top: 50px;
    transition: all .1s ease-out;
  }
  .detail-search {
    display: block;
    transition: all .1s ease-out;
  }
}

.detail-search {
  display: none;
  width: 1360px;
  max-width: 98vw;
  background: #fff;
  border: 1px solid #cdd3d9;
  border-radius: 6px;
  margin: 10px auto;
  .detail-header {
    font-size: 16px;
    font-weight: 500;
    color: #495057;
    letter-spacing: -1px;
    line-height: 50px;
    height: 50px;
    padding: 0 15px;
    border-bottom: 1px solid #e9ebec;
    .btn, .icon {
      font-size: 20px;
      line-height: 50px;
      height: 50px;
    }
    .icon {
      vertical-align: sub;
    }
  }
  .detail-body {
    padding: 15px;

    table {
      tbody {
        th {
          /*display:none;*/
        }
      }
    }
  }
  .detail-footer {
    padding: 5px 0 25px;
  }
}

.detail-search-position {
  position: absolute;
  display: block;
  top: 135px;
  left: 50%;
  margin-left: -700px;
  z-index: 99999;
}

/* header 검색input 에서 상세검색창 열기 */
.searchBoxWrap {
  .detail-search {
    display: block;
    position: absolute;
    top: 33px;
    z-index: 9;
    left: -435px;
  }
}

@media screen and (max-width:1024px) {
  .searchBoxWrap {
    .detail-search {
      position: fixed;
      top: 47px;
      z-index: 9;
      left: 70px;
      width: calc(100vw - 70px);
    }
  }
  .detail-search-position {
    margin-left: -49vw;
  }
}    