/* 대시보드 */

.dashboard {
  padding: 24px;
  .left-div {
    width: 60%;
    margin-right: 24px;   
  }
  .right-div {
    width: calc(40% - 24px);
  }
  .card {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 3px rgba(56,65,74,.1);
    margin: 0 0 24px 0;
    &.card1, &.card2 {
      height: 390px;
      .scroll-y {
        height: 310px;
      }
    }
    &.card2 {
      height: 560px;
      //margin-bottom: 0;
      .scroll-y {
        height: 430px;
      }
    }

    .card-header {
      font-size: 16px;
      font-weight: 500;
      color: #495057;
      letter-spacing: -1px;
      line-height: 60px;
      height: 60px;
      padding: 0 15px;
      border-bottom: 1px solid #e9ebec;
      .sub {
        color: #6d7080;
      }
      .fr {
        font-size: 14px;
        color: #6d7080;
        line-height: 60px;
      }
    }
    .card-body {
      .widget {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        li {
          flex: 0 0 auto;
          width: 25%;
          height: 150px;
          padding: 25px 15px;
          border-right: 1px solid #e9ebec;
          &:nth-last-child(1) {
            border-right: none;
          }
          .widget-title {
            font-size: 16px;
            font-weight: 500;
            color: #495057;
            letter-spacing: -1px;
            padding-bottom: 15px;
          }
          .widget-value {
            padding-bottom: 15px;
            .icon {
              float: left;
              display:flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 3px;
              margin-right: 15px;
              font-size: 24px;
              color: #fff;
            }
            .value {
              float: left;
              font-family: "Inter";
              font-size: 24px;
              font-weight: 700;
              color: #495057;
              line-height: 40px;
              &.value1 {
                font-size: 20px;
                span {
                  color: #6d7080;
                  font-weight: 400;
                  padding-left: 12px;
                }
              }
            }
            .chart {
              float: right;
              margin: -6px -15px 0 0;
            }
          }
          .link {
            font-size: 14px;
            font-weight: 500;
            color: #6d7080;
            letter-spacing: -1px;
          }
        }
      }

      .drive-usage {
        padding: 15px 24px;
        .progress-wrap {
          padding-top: 10px;
          .progress-title {
            font-size: 14px;
            font-weight: bold;
            color: #495057;
            padding: 5px 0;
            span {
              font-weight: 400;
              color: #6d7080;
              padding-left: 10px;
              &.path {
                font-family: 'Roboto';
              }
            }
          }
          .progress {
            width: 100%;
            margin: 10px 0 2px;
            background-color: #eff2f7;
          }
          .percent {
            float: right;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 500;
            color: #89929b;
            line-height: 26px;
          }
        }
      }

      .notice {
        padding: 0 15px;
        li {
          height: 80px;
          padding: 10px 0;
          border-bottom: 1px dotted #e9ebec;
          &:nth-last-child(1) {
            border-bottom: none;
          }
          .th {
            float: left;
            width: 48px;
            height: 58px;
            border-radius: 4px;
            background: #f3f6f9;
            margin-right: 15px;
            font-family: "Inter";
            font-weight: 500;
            text-align: center;
            letter-spacing: -1px;
            p:nth-child(1) {
              font-size: 16px;
              color: #495057;
              padding: 10px 0;
            }
            p:nth-child(2) {
              font-size: 14px;
              color: #6d7080;
            }
            &.sun {
              p {
                color: #f06548;
              }
            }
            &.sat {
              p {
                color: #3577f1;
              }
            }
          }
          .td {
            float: left;
            width: calc(100% - 63px);
            .date {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 500;
              color: #6d7080;
              letter-spacing: -1px;
              padding: 10px 0 14px;
            }
            .title {
              font-size: 16px;
              color: #495057;
              letter-spacing: -1px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &.disabled {
              .date, .title {
                color: #dbdddf;
              }
            }
          }
        }
      }
      .authority {
        table {
          tr:hover {
            background-color: #f2f2f2;
            cursor: pointer;
          }
          th {
            font-size: 14px;
            font-weight: 500;
            color: #6d7080;
            text-align: left;
            height: 45px;
            padding: 0 15px;
            background: #f3f6f9;
            border-top: 1px solid #e9ebec;
            border-bottom: 1px solid #e9ebec;
          }
          td {
            font-size: 14px;
            font-weight: 500;
            color: #495057;
            height: 55px;
            padding: 0 15px;
            .img {
              margin-right: 8px;
              vertical-align: middle;
            }
            .badge {
              display: inline-block;
              font-size: 13px;
              font-weight: bold;
              line-height: 23px;
              height: 25px;
              border: 1px solid;
              border-radius: 3px;
              padding: 0 5px;
              margin-right: 5px;
            }
          }
        }
      }
      .ranking {
       padding: 0 15px;
        li {
          height: 49px;
          padding: 0 20px;
          border-bottom: 1px dotted #e9ebec;
          &:nth-last-child(1) {
            border-bottom: none;
          }
          .fl {
            float: left;
            width: calc(100% - 100px);
            font-size: 16px;
            font-weight: 500;
            color: #495057;
            line-height: 49px;
            .no {
              display: inline-block;
              width: 20px;
              text-align: right;
              margin-right: 10px;
            }
          }
          .fr {
            float: right;
            width: 100px;
            font-family: "Inter";
            font-size: 16px;
            color: #6d7080;
            letter-spacing: -1px;
            line-height: 49px;
            text-align: right;

            .icon {
              font-size: 20px;
              margin-left: 20px;
              line-height: 49px;
              vertical-align: bottom;
            }
          }
        }

        li:hover {
          background-color: #f2f2f2;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width:1024px) {
  .dashboard {
    .left-div {
      width: 100%;
      margin-right: 0;   
    }
    .right-div {
      width: 100%;
    }
    .card {
      .card-body {
        .widget {
          li {
            width: 50%;
            &:nth-child(n-2) {
              border-bottom: 1px solid #e9ebec;
            }
            &:nth-child(2n) {
              border-right: none;
            }            
          }
        }
      }
    }
  }
}