/* 파일 미리보기 modal */
.previewModal {
  .modal-body {
    height: 643px;
  }
  .preview-div {
    .img {
      float: left;
      display:flex;
      justify-content: center;
      align-items: center;
      width: 65%;
      height: 603px;
      overflow: auto;
      img {
        max-width: 100%;
        border:solid 1px #555;
      }
    }
    .info {
      float: right;
      width: calc(35% - 10px);
      .info-title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
      }
      .input-table {
        td {
          padding: 0 0 0 10px;
          .scroll-wrap {
            line-height: 1.2;
            max-height: 257px;
            padding: 5px 0;
            overflow-y: auto;
            .plist{
              li {
                cursor: pointer;
                line-height: 1.5;
                i {
                  padding-right: 5px;
                  vertical-align: text-top;
                }
                &:hover {
                  color: #3577f1;
                  span {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* 프로젝트정보 modal */
.projectInfoModal {
  .project-nm {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    line-height: 28px;
  }
  .input-table {
    td {
      line-height: 1.3;
      padding: 10px;
    }
  }
}