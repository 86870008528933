.inputbox * {
  font-family: "Pretendard", sans-serif !important;
}
.checkBox * {
  font-family: "Pretendard", sans-serif !important;
}

.basic .MuiOutlinedInput-input {
  height: 28px;
  line-height: 28px;
  padding: 0px 10px;
  font-size: 13px;
}