.snb {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
  background: #151529;
  box-shadow: 0 2px 4px rgba(15,34,58,.12);
  padding: 0 0 95px;
  transition: all .1s ease-out;
  z-index: 1002;

  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 70px;
    transition: all .1s ease-out;

    img {
      height: 34px;
    }
  }
  .logo_reduce {
    display: none;
  }

  .snblist {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */ 
    scrollbar-width: none; /* Firefox */ 
    
    &::-webkit-scrollbar { 
     display: none; /* Chrome, Safari, Opera*/ 
    }  
  }
  .snbmenu {    
    padding: 20px;
    margin-bottom: 80px;

    .menu-title {
      font-family: "Inter";
      font-size: 11px;
      font-weight: 700;
      color: #6d7080;
      line-height: 40px;
    }
    .menu {
      height: 47px;
      cursor: pointer;
      .depth1 {
        display: flex;
        .icon1 {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #a3a6b7;
          min-width: 28px;
        }
        .title {
          font-size: 16px;
          font-weight: bold;        
          color: #a3a6b7;
          letter-spacing: -1px;
          line-height: 46px;

          &:after {
            content: "\F0142";
            font-family: Material Design Icons;
            font-size: 1.05rem;
            margin-left: auto;
            position: absolute;
            right: 18px;
            transition: -webkit-transform .2s;
            transition: transform .2s;
            transition: transform .2s,-webkit-transform .2s;
          }
        }
      }
      .submenu {
        height: 0;
        overflow: hidden;
        transition: height .5s ease;
        
        li {
          height: 40px;
  
          .subtitle {
            position: relative;
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #a3a6b7;
            letter-spacing: -1px;
            line-height: 40px;
            padding-left: 28px;
            &:before {
              content: "";
              position: absolute;
              top: 19px;
              left: 5px;
              width: 6px;
              height: 2px;
              opacity: .5;
              background: #a3a6b7;
              transition: all .4s ease-in-out;
            }
          }  
        }
      }
      &.open {
        height: auto;
        .depth1 {
          .title:after {
            transform: rotate(90deg);
          }
        }
        .bx, .title {color: #fff;}        
        .submenu {
          height: auto;
          transition: height .5s ease;
          
          li:hover .subtitle {
            color: #fff;
            &:before {
              background: #fff;
            }  
          }
        }
      }
    }  

  }
  .drive-list {
    padding: 0 20px;
    .info-title {
      font-size: 14px;
      font-weight: bold;
      color: #89929b;
    }
    .progress-wrap {
      padding-top: 10px;
      .progress {
        float: left;
        width: calc(100% - 40px);
        margin: 10px 0;
      }
      .percent {
        float: right;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 500;
        color: #89929b;
        line-height: 26px;    
      }
    }
    .info-txt {
      font-size: 12px;
      color: #89929b;
      letter-spacing: -1px;
    }
  }
}

/* 왼쪽메뉴 축소 */
.reduce {
  .snb {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 70px;

    .logo {
      display: none;
    }
    .logo_reduce {
      display:flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      transition: all .1s ease-out;
  
      img {
        height: 34px;
      }
    }
  
    .snbmenu {    
  
      .menu-title {
        display: none;
      }
      .menu {
        .depth1 {
          display: flex;
          .icon1 {
            min-width: 100%;
            justify-content: center;
          }
          .title {
            display: none;
          }
        }
        .submenu {
          display: none;
        }
        &.open {
          height: 47px;
          .depth1 {
            .title:after {
              transform: rotate(90deg);
            }
          }
          .icon1, .title {color: #fff;}        
          .submenu {
            display: none;
          }
        }
      }    
    }
    .drive-list {
      display: none;
    }
  }
}  


@media screen and (max-width:1024px) {
  .snb {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 70px;

    .logo {
      display: none;
    }
    .logo_reduce {
      display:flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      transition: all .1s ease-out;
  
      img {
        height: 34px;
      }
    }
  
    .snbmenu {    
      padding: 20px;
      margin-bottom: 80px;
  
      .menu-title {
        display: none;
      }
      .menu {
        height: 47px;
        cursor: pointer;
        .depth1 {
          display: flex;
          .icon1 {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #a3a6b7;
            min-width: 100%;
            justify-content: center;
          }
          .title {
            display: none;
          }
        }
        .submenu {
          display: none;
        }
        &.open {
          height: 47px;
          .depth1 {
            .title:after {
              transform: rotate(90deg);
            }
          }
          .bx, .title {color: #fff;}        
          .submenu {
            display: none;
          }
        }
      }    
    }
    .drive-list {
      display: none;
    }
  }

  /* 왼쪽메뉴 펼치기 */
  .padExpand {
    .snb {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 250px;
    
      .logo {
        display:flex;
      }
      .logo_reduce {
        display: none;
      }
 
      .snbmenu {    
        .menu-title {
          display: block;
        }
        .menu {
          .depth1 {
            .icon1 {
              min-width: 28px;
              justify-content: initial;
            }
            .title {
              display: block;
             }
          }
          .submenu {
            display: block;
          }
          &.open {
            height: auto;
            .depth1 {
              .title:after {
                transform: rotate(90deg);
              }
            }
            .icon1, .title {color: #fff;}        
            .submenu {
              display: block;
            }
          }
        }  
    
      }
      .drive-list {
        display: block;
      }
    }
  }
}  