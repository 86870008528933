.deviceInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  flex-flow: row wrap;
  align-items: stretch;

  .pie-chart {
    margin-top: 10px;
  }
}

.search-area {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: row wrap;
  align-items: stretch;
}

@media screen and (max-width:1024px) {
  .deviceInfo {
    padding: 0px;
  }
}