.MuiChip-outlined {
  border-radius: 4px !important;
  font-size: 12px !important;
  background-color: #fff !important;
}

.MuiChip-contained {
  color: #fff !important;
  border-radius: 16px !important;
  font-size: 12px !important;
}

.MuiChip-contained.MuiChip-containedPrimary {
  background-color: #1976d2 !important;
}

.MuiChip-contained.MuiChip-containedError {
  background-color: #d32f2f !important;
}

.MuiChip-contained.MuiChip-containedSuccess {
  background-color: #2e7d32 !important;
}

.MuiChip-contained.MuiChip-containedSecondary {
  background-color: #9c27b0 !important;
}

.MuiChip-contained.MuiChip-containedDefault {
  background-color: #ccc !important;
}

.MuiChip-outlined.MuiChip-outlinedPrimary {
  color: #1976d2 !important;
  border:solid 1px #1976d2 !important;
}

.MuiChip-outlined.MuiChip-outlinedError {
  color: #d32f2f !important;
  border:solid 1px #d32f2f !important;
}

.MuiChip-outlined.MuiChip-outlinedSuccess {
  color: #2e7d32 !important;
  border:solid 1px #2e7d32 !important;
}

.MuiChip-outlined.MuiChip-outlinedSecondary {
  color: #9c27b0 !important;
  border:solid 1px #9c27b0 !important;
}

.MuiChip-outlined.MuiChip-outlinedDefault {
  color: #ccc !important;
  border:solid 1px #ccc !important;
}


/* Button */
.MuiButton-root {
  min-width: auto !important;
  height: 28px;
  font-size: 14px !important;
  font-family: "Pretendard", sans-serif !important;

  &.basic {
    font-size: 14px;
    height: 35px;
    padding: 0 15px;
  }
  &.lg {
    font-size: 17px !important;
    font-weight: 500;
    height: 56px;
  }
  &.blueTxt {
    color: #004896;
  }

  &.whiteTxt {
    color: #fff !important;
  }

  &.bluebg {
    color: #fff;
    background-color: #38589f !important;
    border: 1px solid #38589f;
  }
  &.blackbg {
    color: #fff;
    background-color: #5b646e !important;
    border: 1px solid #5b646e;
  }
}
.HBtn {
  .MuiButton-label {
    color: inherit;
  }
}

.MuiInputBase-input {
  padding: 0px 10px;
}
.MuiInputBase-root {
  border-radius: 0 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}
.MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline legend {
  height: 0;
}
.MuiOutlinedInput-notchedOutline legend {
  height: 0;
}

.css-1ohjqwz-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0 !important;
}

@media screen and (max-width:750px) {
  /* Button */
  .MuiButton-root {
    &.basic {
      font-size: 2.4rem;
      height: 35px;
      padding: 0 15px;
    }
    &.lg {
      font-size: 2.8rem !important;
      font-weight: 500;
      height: 8rem;
    }
  }
}


.btn-search, .btn-refresh, .btn-excel, .btn-grid-save {
  padding: 0px 0px !important;
  width: 28px;
  height: 28px;

  span {
    font-size: 20px;
  }
}

.gridBtn {
  height: 25px;
  padding: 0px 7px !important;
  font-size: 12px !important;
  font-size: 12px !important;
}

.gridBtn1 {
  height: 30px;
  padding: 0px 7px !important;
}

.white {
  padding: 0px 15px;
  line-height: 36px;

  .MuiButton-label {
    color: #fff !important;
  }

  .MuiChip-label {
    color: #fff !important;
  }

  .MuiChip-labelSmall {
    color: #fff !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3%;
  height: calc(100% - 4px);
  margin-top: 2px !important;
}

.error-msg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  height: calc(100% - 2px);
  margin-top: 1px !important;
}

.Mui-error {
  background-color: rgb(255, 229, 229) !important;

  &.selectBox > div > div:first-child {
    background-color: rgb(255, 229, 229) !important;
    border: solid 2px red;
  }

  &.CheckboxLabel {
    background-color: transparent !important;
  }
}


/* ToggleButton */
.toggleBtn.MuiToggleButtonGroup-root {
  .MuiToggleButton-root {
    height: 38px !important;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
    padding: 0 15px;
    border: solid 1px #eee !important;
  }
  .MuiToggleButton-root.Mui-selected {
    color: #fff;
    background-color: #22282f !important;

    .MuiToggleButton-label {
      color: #fff;
    }
  }
}

/* Toggleutton1 */
.toggleBtnText1.MuiToggleButtonGroup-root {
  .MuiToggleButton-root {
    height: 26px !important;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 0 10px;
    background-color: #fff;
  }
  .MuiToggleButton-root.Mui-selected {
    color: #fff;
    background-color: #22282f;
  }
}

/* Selectbox */
.selectBox {
  .MuiInputBase-root {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #636363;
    text-transform: initial;

    .MuiInputBase-input {
      height: 28px;
      padding: 0px 25px 0px 15px;
      border: 1px solid #ccc;
      border-radius: 0;
      box-sizing: border-box;
      font-size: 13px;

      &:focus {
        background-color: #fff;
      }


    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  &.h24 {
    .MuiInputBase-input {
      height: 22px;
    }
  }
  &.w150 {
    .MuiInputBase-input {
      width: 150px;
    }
  }
}

.modal-footer {
  .MuiButtonBase-root {
    padding: 6px 20px;
  }
}
 /*
.Checkbox.MuiCheckbox-root {
 width: 16px;
  height: 16px;
  color: #fff;
  svg {
    font-size: 1em;
  }
}
*/
.Checkbox.MuiCheckbox-root.Mui-checked {
  color: #1e57a1;
}

.CheckboxLabel.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 13px;
  font-weight: 500;
  color: #6a6a6a;
  padding-left: 0;
}

.Checkbox.MuiCheckbox-root.Mui-checked {
  /*color: #fff;*/
}

.MuiButton-outlined.MuiButton-colorInherit {
  border-color: #bbb !important;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiToggleButtonGroup-grouped {
  min-width: 60px !important;
  border:solid 1px #ccc !important;
  height: 28px;
  line-height: 28px;
  padding: 0px 10px !important;

  &.Mui-selected {
    background-color: #555 !important;
    color: #fff !important;
    border:solid 1px #555 !important;
  }
}

.no-min-width {
  button {
    min-width: auto !important;
  }
}

input:disabled {
  background-color: #eee;
}

.input-area-btn {
  line-height: 30px !important;
}

.MuiButton-containedSuccess {
  color: #fff !important;
  background-color: #2e7d32 !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-outlinedSuccess {
  color: #2e7d32 !important;
  background-color: #fff !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-containedError {
  color: #fff !important;
  background-color: #d32f2f !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-outlinedError {

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.icon-btn {
  padding: 5px !important;
}

/* InputEx label font-size */
label.MuiInputLabel-formControl  {
  font-size: 12px;
  line-height: 14px;
  top: -1px;
}

.search-input {
  label.MuiInputLabel-formControl  {
    font-size: 13px;
    line-height: 12px;
  }
}

/* InputEx Adornment font-size */
.MuiInputAdornment-root {
  p {
    font-size: 11px;
    line-height: 100%;
    padding-top: 1px;
  }
}

.chip-tiny {
  padding: 0px !important;
  margin: 0px !important;
  height: 16px !important;
  font-size: 11px !important;
  line-height: 100% !important;
  border-radius: 4px !important;

  .MuiChip-labelSmall {
    padding: 0px 4px !important;
  }
}

.MuiOutlinedInput-input {
  padding: 0px 10px !important;
  min-height: 28px;
  line-height: 28px;
  font-size: 13px !important;
}

@media screen and (max-width:1024px) {

  .MuiToggleButtonGroup-grouped {
    padding: 0 6px !important;
    font-size: 11px !important;
    min-width: auto !important;
  }  

}