body {
  background: #ddd;
}

.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.print-page-top {
  page-break-before:always;
  background: #fff;
  margin-top: 60px;
}

.print-section {
  position: relative;
  width: 20.9cm;
  height: 29.5cm;
  padding: 50px 50px;
  margin: 0 auto;
  background: #fff;

  .title {
    font-size: 24px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 30px;
  }
  .date {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
  .report-tbl {
    table-layout: fixed;
    th {
      font-size: 12px;
      height: 25px;
      border: 1px solid #aaa;
      border-bottom: none;
      text-align: center;
    }
    td {
      font-size: 10px;
      height: 21px;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tl {
        text-align: left;
        padding-left: 5px;
      }
      &.tr {
        text-align: right;
        padding-right: 5px;
      }
      &.red {
        color: red;
      }
      &.small {
        font-size: 9px;
      }
    }
    thead.small,
    tbody.small {
      th, td {
        font-size: 9px;
      }
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: calc(100% - 80px);
    .guide-txt {
      font-size: 10px;
      line-height: 1.3;
      padding: 10px 0;
      .space {
        display: inline-block;
        width: 18px;
      }
    }
    .footer {
      padding-top: 5px;
      border-top: 2px solid #222;
      .com {
        float: left;
        font-size: 10px;
      }
      .footer-right {
        float: right;
        .document-no {
          font-size: 10px;
          padding-right: 30px;
        }
        .page {
          font-size: 10px;
        }
      }

    }
  }

  .page-no {
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    width: calc(100% - 80px);
    text-align: center;
    font-size: 12px;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  body {
    padding: 0;
    margin: 0;
    background: #fff;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .btn-area {
    display:none;
  }

  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }

  .print-section {
    width: 21cm;
    height: 29.7cm;
    padding: 1.5cm 1.5cm 2cm 1.5cm;
    margin: 0px;
    background: #fff;
  }

  .print-page-top {
    page-break-before: always;
    background-color: #fff;
    margin-top: 0px;
  }

  .input-table {
    table-layout: fixed;
    border-collapse: collapse;
    .layout-small {
      height: 25px !important;
      text-align: center;
        th {
          border: solid 1px #bdc3c7 !important;
        }

        td {
          font-size: 14px;
          border: solid 1px #bdc3c7 !important;
          height: auto !important;
       }
    }
    .layout {
      height: 32px !important;
      text-align: center;
        th {
          border: solid 1px #bdc3c7 !important;
        }

        td {
          font-size: 14px;
          border: solid 1px #bdc3c7 !important;
       }
    }

    th {
      background-color: #f2f2f2;
      font-size: 13px !important;
      color: #666;
      border: solid 1px #ddd;
      font-weight: bold;
      text-align: center;
      height: 40px !important;
    }

    td {
      font-size: 13px;
      color: #333;
      border: solid 1px #ddd;
      padding: 2px;
      height: 40px !important;
    }
  }
}