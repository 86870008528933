.header, .userHeader {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  height: 70px;
  background: #1c1c36;
  transition: all .1s ease-out;
  z-index: 1002;

  .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin: 0 auto;
    padding: 0 24px 0 0.75rem;
  }
  .left-area {
    display: flex;
    align-items: center;
  }
  .menu-btn {
    height: 70px;
    .hamburger-icon {
      display: inline-block;
      height: 14px;
      position: relative;
      width: 20px;
      span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #e9ebec;
        transition: .3s cubic-bezier(.8,.5,.2,1.4);
        &:first-child {
          top: 0;
          width: 80%;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          bottom: 0;
          width: 60%;
        }
      }
    }
    .hamburger-icon.open {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      span:first-child {
        left: 1px;
        top: 5px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        transition-delay: .15s;
        width: 20px;
      }

      span:nth-child(2) {
        left: 3px;
        top: 13px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        transition-delay: 50ms;
        width: 10px;
      }

      span:nth-child(3) {
        left: 9px;
        top: 13px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transition-delay: .1s;
        width: 10px;
      }
    }

    &:hover {
      span {
        &:first-child {
          top: -1px;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          bottom: -1px;
        }
      }
    }
  }

  .header-path {
    .page-title {
      padding-left: 15px;
      li {
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: #a3a6b7;
        letter-spacing: -1px;
        padding-left: 10px;
        line-height: 18px;

        .icon {
          font-size: 14px;
          font-weight: 500;
          color: #a3a6b7;
          padding-right: 5px;
        }

        &:nth-last-child(1) {
          font-size: 16px;
          font-weight: bold;
          color: #f3f3f9;
          letter-spacing: -1px;
          padding-left: 5px;
        }
      }
    }
  }
  .searchBoxWrap {
    position: relative;
    .searchBox {
      position: relative;
      width: 490px;
      input {
        font-size: 14px;
        color: #fff;
        line-height: 3% !important;
        height: 38px !important;
        padding: 0px 15px !important;
      }
      fieldset {
        border: none;
      }
      .search-input {
        height: 38px;
        border-radius: 4px;
        background-color: #282840;
        padding: 0 30px 0 40px;
        &:focus-visible {
          outline: none;
        }
      }
      .search-widget-icon {
        color: #878a99;
        font-size: 18px;
        left: 13px;
        line-height: 38px;
        position: absolute;
        top: 0;
        z-index: 10;
        &.search-widget-icon-right {
          left: auto;
          right: 7px;
          cursor: pointer;
        }
      }
    }
  }

  .right-area {
    display: flex;
    justify-content: center;
    align-items: center;
    .pad-only {display: none;}
    .util-btn-wrap {
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      li {
        float: left;
        min-width: 56px;
        min-height: 56px;
        display: inline-flex;
        justify-content: space-around;
      }
    }
    .util-btn {
      font-size: 22px;
      color: #a3a6b7;
      width: auto;
      min-width: 42px;
      height: 56px;
      margin-left: 4px;
      border-radius: 50%;
      flex-flow: column;
      justify-content: space-around;
      gap: 8px;
      &:hover {
        color: #fff;
        background-color: hsla(0,0%,100%,.07);
      }
    }
    .btn-title {
      color: #fff;
      font-size: 12px;
      text-align: center;
    }
    .user-btn {
      min-width: 120px;
      height: 70px;
      padding: 0 15px;
      background-color: #2a2a50;
      border-radius: 0;
      margin-left: 20px;
      > span {
        display: flex;
      }
      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .txt {
        display: inline-flex;
        flex-direction: column;
        text-align: left;
        .position {
          font-size: 12px;
          font-weight: 500;
          color: #a3a6b7;
          letter-spacing: -1px;
          line-height: 15px;
        }
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          letter-spacing: -1px;
          line-height: 17px;
          opacity: 0.851;
        }
      }
    }
  }

  .util-area {
    float: right;

    .top-icon {
      display: inline-block;
      width: 59px;

      .right-icon {
        font-size: 25px;
        color: #606060;
        height: 60px;
        line-height: 60px;
      }
    }
    .login-info {
      display: inline-block;
      width: 60px;
      height: 60px;
      padding-top: 10px;
      vertical-align: bottom;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

  }
}

/* 왼쪽메뉴 축소 */
.reduce {
  .header {
    position: fixed;
    top: 0;
    left: 70px;
    width: calc(100% - 70px);
    height: 70px;

    .menu-btn {
      .hamburger-icon {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        span:first-child {
          left: 1px;
          top: 5px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          transition-delay: .15s;
          width: 20px;
        }

        span:nth-child(2) {
          left: 3px;
          top: 13px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          transition-delay: 50ms;
          width: 10px;
        }

        span:nth-child(3) {
          left: 9px;
          top: 13px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          transition-delay: .1s;
          width: 10px;
        }
      }

      &:hover {
        span {
          &:first-child {
            top: 0px;
          }
          &:nth-child(2) {
            top: 8px;
          }
          &:nth-child(3) {
            top: 8px;
            bottom: auto;
          }
        }
      }
    }
  }
}


/* 사용자 header */
.userHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #1c1c36;
  transition: all .1s ease-out;
  z-index: 1002;
  .navbar-header {
    padding: 0 24px;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    transition: all .1s ease-out;

    img {
      height: 34px;
    }
  }
}

@media screen and (max-width:1300px) {
  .header, .userHeader {
    .right-area {
      /*
      .pad-only {
        display: block;
      }
      .util-btn-wrap {
        display: none;
        position: fixed;
        top: 71px;
        right: 88px;
        width: 420px;
        height: 280px;
        padding: 0 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 8px #ddd;
        &.view {
          display: block;
        }
        .view-title {
          font-size: 16px;
          font-weight: 500;
          color: #495057;
          line-height: 58px;
          padding-left: 5px;
          border-bottom: 1px dashed #e8eaeb;
        }
        li {
          float: left;
          width: 100px;
          text-align: center;
          padding: 25px 0 10px;
          &:hover {
            .util-btn {
              background-color: #d6d5eb;
            }
            .btn-title {
              font-weight: bold;
            }
          }
        }
        .util-btn {
          color: #432ca6;
          width: 48px;
          height: 48px;
          background: #f1f4f8;
          i {
            font-size: 18px;
          }
        }
        .btn-title {
          display: block;
          font-size: 13px;
          color: #495057;
          text-align: center;
          padding-top: 5px;
        }
      }
      .pad-btn {
        float: left;
      }
      */
    }
  }
}

@media screen and (max-width:1024px) {
  .header, .userHeader {
    position: fixed;
    top: 0;
    left: 70px;
    width: calc(100% - 70px);
    height: 70px;

    .menu-btn {
      .hamburger-icon {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        span:first-child {
          left: 1px;
          top: 5px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          transition-delay: .15s;
          width: 20px;
        }

        span:nth-child(2) {
          left: 3px;
          top: 13px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          transition-delay: 50ms;
          width: 10px;
        }

        span:nth-child(3) {
          left: 9px;
          top: 13px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          transition-delay: .1s;
          width: 10px;
        }
      }

      &:hover {
        span {
          &:first-child {
            top: 0px;
          }
          &:nth-child(2) {
            top: 8px;
          }
          &:nth-child(3) {
            top: 8px;
            bottom: auto;
          }
        }
      }
    }

    .searchBoxWrap {
      .searchBox {
        width: 28rem;
      }
    }

    .right-area {
      .user-btn {
        margin-left: 0;
        img {
          margin-right: 0;
        }
        .txt {
          display: none;
        }
      }
    }
  }

  /* 사용자 header */
  .userHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #1c1c36;
    transition: all .1s ease-out;
    z-index: 1002;
    .navbar-header {
      padding: 0 24px;
    }
    .logo {
      display:flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      transition: all .1s ease-out;

      img {
        height: 34px;
      }
    }
  }
}