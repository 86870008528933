@charset "utf-8";

@import url(font.css);
@import url(component.css);
@import url(./component/aggrid.scss);

@import url(./plugins/icons/_materialdesignicons.scss);
@import url(./plugins/icons/_boxicons.scss);
@import url(./plugins/icons/_remixicon.scss);

html {height:100%;}
body {margin:0;padding:0;height:100%;}

body * {
  font-family: "Pretendard", sans-serif;
  line-height: 1;
	letter-spacing: 0px;
  box-sizing: border-box;
	padding: 0;
	margin: 0;
}
hr {display:none}
ul {list-style-type: none;}
a {
  cursor: pointer;
  text-decoration: none;
  color: #222;
}
a:link, a:visited {text-decoration:none}
a:hover, a:focus, a:active {text-decoration:none}

table {
  width: 100%;
  border-collapse:collapse;
	border-spacing: 0;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

@media screen and (max-width:1024px) {
  html {font-size: 0.975vw;} /* 10px */
}

.rstcustom__collapseButton {
  width: 20px;
  height: 22px;
  transform: translate3d(-50%, -50%, 0);
}

.rstcustom__toolbarButton {
  div:first-child {
    width: 14px !important;
    margin-right: 5px !important;
  }
}

.tree-selected-parent {
  background-color: #0378d4;
  color: #fff;
}

.rstcustom__rowWrapper {
  height: 25px;
  cursor: pointer;
}

.rstcustom__rowWrapper > div:first-child {
  height: 25px;
}