.modal-left-area {
  float: left;
  width: 370px;
  height: 670px;
  padding: 0px;
  background: #fff;

  .left-header {
    padding-bottom: 15px;

    .h2 {
      font-size: 16px;
      font-weight: 500;
      color: #555;
      float: left;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .searchBox {
      position: relative;
      width: 100%;
      margin: 17px auto;
      border: 1px solid #cdd3d9;

      input {
        font-size: 14px;
        line-height: 30px !important;
        height: 30px !important;
        padding: 0px 15px 0 0 !important;
      }
      fieldset {
        border: none;
      }
      .search-input {
        height: 30px;
        border-radius: 4px;
        background-color: #fff;
        padding: 0 0 0 40px;
        &:focus-visible {
          outline: none;
        }
      }
      .search-widget-icon {
        color: #878a99;
        font-size: 22px;
        left: 13px;
        line-height: 30px;
        position: absolute;
        top: 0;
        z-index: 10;
      }
    }
    .cnt {
      float: left;
      font-size: 12px;
      font-weight: 500;
      color: #878a99;
    }
    .sort {
      position: relative;
      float: right;
      font-size: 12px;
      font-weight: 500;
      color: #878a99;
      .selected {
        cursor: pointer;
      }
      .select-list {
        display: none;
        position: absolute;
        top: 20px;
        right: 0;
        width: 120px;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0 3px 8px rgba(56,65,74,.2);
        li {
          font-size: 14px;
          color: #212529;
          line-height: 30px;
          height: 30px;
          padding-left: 20px;
          cursor: pointer;
          &:hover {
            background: #f3f6f9;
          }
        }
      }

      &.view {
        .select-list {
          display: block;
          z-index: 1;
        }
      }
    }
  }
  .list {
    height: 510px;

    ul {
      border-top: 1px dotted #e9ebec;

      li {
        font-size: 14px;
        font-weight: 500;
        color: #495057;
        padding: 10px 0;
        border-bottom: 1px dotted #e9ebec;
        cursor: pointer;
        &.on {
          color: #3577f1;
          font-weight: bold;
        }

        .state {
          width: 40px;
        }
        .title {
          width: calc(100% - 40px);
          > div:nth-child(1) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .MuiChip-colorSuccess {
          background: #212529;
        }
      }
    }
  }

  .reduce-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    color: #3577f1;
    cursor: pointer;
  }
}

.modal-right-area {
  float: right;
  width: calc(100% - 380px);
  background: #fff;
  .h2 {
    font-size: 18px;
    font-weight: 500;
    color: #3577f1;
    padding-top: 15px;
  }
  .grid-icon {
    position: relative;
    i {
      color: #666;
    }
    .popup-menu {
      display: block;
      position: fixed;
      top: 36px;
      right: 10px;
      width: 170px;
      padding: 5px 0;
      background: #fff;
      box-shadow: 0 7px 10px rgba(56,65,74,.1);
      li {
        font-size: 14px;
        color: #212529;
        line-height: 30px;
        height: 30px;
        text-align: left;
        padding: 0 20px;
        cursor: pointer;
        &.down-line {
          line-height: 35px;
          height: 35px;
          margin-bottom: 5px;
          border-bottom: 1px dotted #ddd;
        }
        &:hover {
          background: #f3f6f9;
        }
        .hotkey {
          float: right;
          font-size: 12px;
          line-height: 30px;
        }
      }
    }

    &.view {
      .select-list {
        display: block;
      }
    }
  }
  .tempFolder {
    .tempBtn {
      display: none;
    }
    &.view {
      .basicBtn {
        display: none;
      }
      .tempBtn {
        display: block;
      }
    }
  }
}

@media screen and (max-width:1024px) {


}