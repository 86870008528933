.note-modal {
  display: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .modal-wrap {
    height: auto;
    max-height: 100%;
    max-width: 94vw;
    transform: translate(0);
    background-color: #fff;
    border-radius: 10px;

    .modal-header {
      height: 40px;
      background-color: #392c7b;
      border-radius: 0px;
      padding: 0 10px;

      .modal-title {
        float: left;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        line-height: 40px;
      }

      .modal-sub-title {
        float: left;
        font-size: 12px;
        font-weight: 100;
        color: #fff;
        line-height: 40px;
      }

      .modal-sub {
        float: right;
        font-weight: 100;
        font-size: 10px;
        color: #fff;
        line-height: 50px;
      }

      .close-btn {
        float: right;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        margin-top: 8px;
        color: #fff;
        opacity: 0.7;
        font-size: 24px !important;

        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      padding: 20px;
      max-height: 85vh;
      overflow-y: auto;

      label {
        display: block;
        width: 100%;

        .TextField-input.w100 {
          width: calc(100% - 104px);
        }

        .MuiInputBase-input {
         padding: 7px 10px !important;
        }

        .TextField-input.MuiFormControl-root {
          .MuiOutlinedInput-root {
            width: 100%;
          }
        }

      }

      .MuiInputBase-input {
        padding: 0px 10px !important;
        min-height: 28px;
        line-height: 28px;
        font-size: 13px;
      }

      .pwHelp {
        padding: 5px;
        border: 1px solid;
        color: gray;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 10px;
      border-top: 1px solid #e0e0e0;
      border-radius: 0 0 8px 8px;

      .modal-footer-center {
        width: 100%;
        text-align: center;
      }

      .whitebg {
        color: #000;
        background-color: #fff !important;
      }

      .whitebg.MuiButton-outlined {
        padding: 5px 15px !important;
      }
    }
  }
}

.Btn {
  padding: 5px 15px !important;

  &.btn-search {
    padding: 6px 15px !important;
  }
}