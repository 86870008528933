.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* ag-grid */
.ag-theme-balham .ag-header-row {
  height: 36px !important;
}
.ag-theme-balham .ag-row {
  height: 36px !important;
}
.ag-theme-balham.header-hide {
  .ag-row {
    height: 70px !important;
  }
}
.ag-theme-balham .ag-cell, .ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  font-size: 12px;
  font-weight: 500;
  color: #636363;
  line-height: 32px !important;
  border-right: solid 1px #eee;
}
.ag-body-viewport.ag-layout-normal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar {
   display: none; /* Chrome, Safari, Opera*/
}

.ag-header-cell, .ag-header-group-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center !important;
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
  justify-content: center;
}

.ag-header-cell-sortable .ag-header-cell-label {
  justify-content: center;
}

.header-hide {
  .ag-header {
    display: none;
  }

  .normal {
    display: inline-block;
    width: 95%;
    text-wrap: pretty;
    font-size: 14px !important;
    font-weight: normal;
    line-height: 120%;
    color: #333;
  }
}

.ag-header-select-all {
  margin-left: 8px;
}

.custom-tooltip {
  width: 200px;
  padding: 5px;
  color: var(--ag-foreground-color);
  background-color: #5577cc;

  .custom-tooltip-text {
    color: #fff !important;
    margin: 5px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    line-height: 130%;
  }

  .custom-tooltip-title {
    margin: 5px;
    color: #fff !important;
    font-weight: bold !important;
  }
}

.aggrid-cell-max {
  background-color: #c1c6e8;
}

.aggrid-cell-min {
  background-color: #e8c1c1;
}