.Login {
  position: fixed;
  top: 0px;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // min-height: 800px;
  background: url('/assets/images/login_background.jpg') 50% 50%;
  background-size: cover;

  .login-wrap {
    width: 440px;
    height: 583px;
    padding: 0 60px;
    border-radius: 14px;
    background-color: #1b1c1d;
    box-shadow: 0 0 10px;
    opacity: 0.902;

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      padding: 5px;
    }
  }
  .login-logo {
    background: url('/assets/images/login_logo.png') no-repeat 50%;
    margin: 80px auto 30px;
    height: 66px;
  }
  .com-name {
    font-size: 32px;
    font-weight: bold;
    color: #d1bc98;
    text-align: center;
    margin-bottom: 40px;
  }
  .inputbox {
    width: 100%;
    height: 56px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 15px;
    opacity: 1;

    .inputbox {
      font-size: 16px;
      height: 56px;
    }

    .MuiFormControl-root {
      font-size: 16px;
      flex-direction: initial;
      margin-bottom: 15px;

      .MuiOutlinedInput-root {
        font-size: 16px;
        color: #222;
        input {
          height: 56px;
          padding: 0 20px;
        }
      }
      .MuiOutlinedInput-root fieldset {
        border: none;
      }
    }
  }

  /* CheckBox */
  .idsave {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    &.CheckboxLabel.MuiFormControlLabel-root {
      .MuiFormControlLabel-label {
        font-size: 13px;
        font-weight: 500;
        color: #fff;
        padding-left: 0;
      }
    }
    &.MuiCheckbox-root.Mui-checked {
      color: #fff;
    }
  }
  .checkbox.MuiFormControlLabel-root {
    height: 28px;

    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
      background: #dce1e8;
      border: 1px solid #d6d6d6;
    }
    .MuiCheckbox-root {
      color: #fff;
    }
    .MuiTypography-body1 {
      font-size: 16px;
    }
  }


  .login-btn {
    font-size: 18px !important;
    font-weight: bold;
    color: var(--font-color-black);
    width: 100%;
    height: 56px;
    border-radius: 4px !important;
    background-color: #d1bc98 !important;
    margin: 20px 0;
  }

  .footer-txt {
    position: absolute;
    bottom: 50px;
    font-size: 11px;
    font-weight: bold;
    color: #d1bc98;
    text-align: center;
    opacity: 0.8;
    filter: drop-shadow(0px 1px 2.5px #000000);
  }
}

