.search-list {
  padding: 0 24px 24px;
  height: calc(100vh - 100px);

  .tab-header {
    .tab-menu {
      width: max-content;
      padding: 0px;
      margin: 10px 0px;
      li {
        float: left;
        font-size: 24px;
        color: #495057;
        letter-spacing: -1px;
        line-height: 26px;
        padding: 0 22px;
        cursor: pointer;
        &.active {
          font-weight: 800;
          color: #4b38b3;
          text-decoration: underline;
        }
      }
    }
    .result {
      .fl {
        font-size: 16px;
        font-weight: 500;
        color: #495057;
        line-height: 26px;
      }
      .fr {
        font-size: 14px;
        color: #495057;
        line-height: 26px;
      }
    }
    .tag {
      font-size: 14px;
      color: #495057;
      font-weight: 500;
      line-height: 26px;
      margin-top: 15px;
      ul {
        display: inline-block;
        vertical-align: bottom;
        padding: 0 0 0 5px;
        li {
          float: left;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          line-height: 26px;
          height: 26px;
          border-radius: 13px;
          padding: 0 16px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }

  .search-input {
    .input-txt {
      font-size: 24px;
      font-weight: bold;
      color: #495057;
      letter-spacing: -1px;
      text-align: center;
      padding-bottom: 30px;
    }
    .searchBox1 {
      position: relative;
      width: 510px;
      margin: 0 auto;
      .search-input {
        height: 50px;
        border-radius: 6px;
        background-color: #fff;
        padding: 0 0px 0 0px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .searchBoxBlurList  {
    width: 600px;
    margin: 0 auto;

    .searchBox {
      position: relative;
      width: 400px;
      float: left;
      border: 1px solid #cdd3d9;
      margin-left: 100px;
      input {
        font-size: 16px;
        line-height: 36px !important;
        height: 36px !important;
        padding: 0px 10px !important;
      }
      fieldset {
        border: none;
      }
      .search-input {
        height: 36px;
        border-radius: 6px;
        background-color: #fff;
        padding: 0 30px;
        &:focus-visible {
          outline: none;
        }
      }
      .search-widget-icon {
        color: #878a99;
        font-size: 22px;
        left: 13px;
        line-height: 36px;
        position: absolute;
        top: 0;
        z-index: 10;
        &.search-widget-icon-right {
          left: auto;
          right: 7px;
          cursor: pointer;
        }
      }
    }
  }

  .design-option {
    position: absolute;
    left: 50%;
    margin-left: -90px;
    margin-top: 40px;
  }

  .help-inputbox {
    position: absolute;
    top: 40px !important;
  }

  .result {
    margin-left:20px;
    display: flex;
    align-items: center;
    line-height: 50px;
    height: 50px;
  }
}


.search-list-result {
  margin-top: 10px;
  padding-bottom: 20px;
  .design {
    margin: 0 0 20px;
    li {
      float: left;
      width: calc((100% - 72px) / 5);
      height: 270px;
      margin: 9px;
      &:nth-child(5n + 1) {
        margin-left: 0;
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      .list {
        position: relative;
        display:flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        .img-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: .5;
          background-image: linear-gradient(transparent, #000);
        }
        .keyword {
          position: absolute;
          left: 30px;
          bottom: 30px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          z-index: 2;
          width: 300px;
        }
        .info {
          position: absolute;
          left: 30px;
          bottom: 0;
          font-size: 15px;
          font-weight: 500;
          color: #999;
          z-index: 2;
          opacity: 0;
          width: 300px;
        }
        .darkness {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0;
          z-index: 1;
        }
      }

      &:hover {
        .list .darkness {
          opacity: 0.7;
          transition: all .5s;
        }
        .list .keyword {
          bottom: 65px;
          transition: all .3s;
        }
        .list .info {
          opacity: 1;
          bottom: 30px;
          transition: all .3s;
        }
      }
    }
  }
  .project-file {
    // padding: 0 0 20px;
  }
}

@media screen and (max-width:1024px) {
  .search-list-result {
    margin-top: 10px;
    padding-bottom: 20px;
    .design {
      margin: 0 0 20px;
      li {
        float: left;
        width: calc((100% - 36px) / 3);
        height: 270px;
        margin: 9px;
        &:nth-child(5n + 1) {
          margin-left: 9px;
        }
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &:nth-child(5n) {
          margin-right: 9px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .list {
          .keyword {
            font-size: 15px;
            width: 250px;
          }
        }

      }
    }
  }
}

.search-pagination {
  position: absolute;
  left: 50%;
  margin-left: -350px;
  z-index: 9999;
  width: 700px;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}

.search-pagination1 {
  position: absolute;
  left: 150%;
  margin-left: -350px;
  z-index: 9999;
  width: 700px;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}

.search-pagination2 {
  position: absolute;
  left: 250%;
  margin-left: -350px;
  z-index: 9999;
  width: 700px;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}