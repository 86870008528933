.project {
  position: relative;
  height: calc(100vh - 70px);
  padding: 5px;
  .left-area {
    position: absolute;
    width: 370px;
    height: calc(100% - 10px);
    padding: 25px;
    background: #fff;

    .left-header {
      padding-bottom: 5px;

      .h2 {
        font-size: 16px;
        font-weight: 500;
        color: #555;
        float: left;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .searchBox {
        position: relative;
        width: 100%;
        margin: 17px auto;
        border: 1px solid #cdd3d9;

        input {
          font-size: 14px;
          line-height: 30px !important;
          height: 30px !important;
          padding: 0px 15px 0 0 !important;
        }
        fieldset {
          border: none;
        }
        .search-input {
          height: 30px;
          border-radius: 4px;
          background-color: #fff;
          padding: 0 0 0 40px;
          &:focus-visible {
            outline: none;
          }
        }
        .search-widget-icon {
          color: #878a99;
          font-size: 22px;
          left: 13px;
          line-height: 30px;
          position: absolute;
          top: 0;
          z-index: 10;
        }
      }
      .cnt {
        float: left;
        font-size: 12px;
        font-weight: 500;
        color: #878a99;
      }
      .sort {
        position: relative;
        float: right;
        font-size: 12px;
        font-weight: 500;
        color: #878a99;
        .selected {
          cursor: pointer;
        }
        .select-list {
          display: none;
          position: absolute;
          top: 20px;
          right: 0;
          width: 120px;
          padding: 10px 0;
          background: #fff;
          box-shadow: 0 3px 8px rgba(56,65,74,.2);
          z-index: 1;
          li {
            font-size: 14px;
            color: #212529;
            line-height: 30px;
            height: 30px;
            padding-left: 20px;
            cursor: pointer;
            &:hover {
              background: #f3f6f9;
            }
          }
        }

        &.view {
          .select-list {
            display: block;
          }
        }
      }
    }
    .list {
      height: calc(100vh - 350px);
      border-bottom: solid 1px #ccc;
      padding-bottom: 10px;

      ul {
        border-top: 1px dotted #e9ebec;
        padding: 0px !important;

        li {
          font-size: 14px;
          font-weight: 500;
          color: #495057;
          padding: 0px 0;
          border-bottom: 1px dotted #e9ebec;
          cursor: pointer;
          &.on {
            color: #3577f1;
            font-weight: bold;
          }

          .state {
            width: 40px;
          }
          .title {
            width: calc(100% - 25px);
            > div:nth-child(1) {
              white-space: wrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2; // 원하는 라인수
              -webkit-box-orient: vertical;
            }
          }
          .project-info {
            width: calc(100% - 25px);

            .project-nm {
              width: 100%;
              height: 40px;
              white-space: wrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              -webkit-line-clamp: 2; // 원하는 라인수
              -webkit-box-orient: vertical;
            }

          }
        }
      }
    }

    .reduce-icon {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 24px;
      color: #3577f1;
      cursor: pointer;
    }
  }
  .reduce-wrap {display: none;}
  .right-area {
    float: right;
    width: calc(100% - 375px);
    min-height: calc(100vh - 235px);
    background: #fff;
    margin-left: 5px;
    overflow-x: auto;
    overflow-y: hidden;

    .tab-header {
      margin: 15px 15px 0px 15px;

      .tab-menu {
        font-size: 20px;
        font-weight: bolder;
      }
    }

    .tab-contents {
      height: calc(100vh - 140px);
      padding-bottom: 20px;
      overflow-y: auto;
      padding: 0px 15px 15px 15px;
    }
    .h2 {
      font-size: 18px;
      font-weight: 500;
      color: #3577f1;
      padding-top: 15px;
    }
    .grid-icon {
      position: relative;
      i {
        color: #666;
      }
      .popup-menu {
        display: block;
        position: fixed;
        top: 36px;
        right: 10px;
        width: 170px;
        padding: 5px 0;
        background: #fff;
        box-shadow: 0 7px 10px rgba(56,65,74,.1);
        li {
          font-size: 14px;
          color: #212529;
          line-height: 30px;
          height: 30px;
          text-align: left;
          padding: 0 20px;
          cursor: pointer;
          &.down-line {
            line-height: 35px;
            height: 35px;
            margin-bottom: 5px;
            border-bottom: 1px dotted #ddd;
          }
          &:hover {
            background: #f3f6f9;
          }
          .hotkey {
            float: right;
            font-size: 12px;
            line-height: 30px;
          }
        }
      }

      &.view {
        .select-list {
          display: block;
        }
      }
    }
    .tempFolder {
      .tempBtn {
        display: none;
      }
      &.view {
        .basicBtn {
          display: none;
        }
        .tempBtn {
          display: block;
        }
      }
    }
  }
  .footer-txt {
    height: 30px;
    padding: 0 10px;
    background: #f3f3f9;
    margin-top: 5px;

    div {
      font-size: 12px;
      line-height: 30px;
    }
  }

  &.reduce {
    .left-area {
      display: none;
    }
    .reduce-wrap {
      display: block;
      position: absolute;
      width: 40px;
      height: calc(100% - 10px);
      padding: 0;
      background: #fff;
      .reduce-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        color: #3577f1;
        cursor: pointer;
      }
    }
    .right-area {
      width: calc(100% - 45px);
      transition: all .3s;
    }
  }
}

.fileBox {
  overflow-x: auto;
  padding: 10px 5px;
  border: 1px solid #ddd;
  ul {
    width: max-content;
    li {
      position: relative;
      float: left;
      width: 210px;
      padding: 0 5px;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .name {
        font-size: 14px;
        line-height: 1.5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .size {
        font-size: 12px;
      }
      .hover-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        .darkness {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0;
          z-index: 1;

        }
        .Btn {
          position: relative;
          z-index: 2;
          margin-top: 50px;
        }
      }
      &:hover {
        .hover-wrap {
          opacity: 1;
        }
        .darkness {
          opacity: 0.7;
          transition: all 0.5s;
        }
        .Btn {
          margin: 0;
          transition: all 0.5s;
        }
      }
    }
  }
}

/* 프로잭트 정보 조회할 권한없음 표시 */
.no-permission {
  display:flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
  .infoTxt {
    font-size: 24px;
    color: #ef5e43;
    line-height: 34px;
    text-align: center;
    background: url(/assets/images/icon_lock.png) no-repeat 50% 0;
    padding-top: 120px;
  }
}

@media screen and (max-width:1024px) {
  .project {
    width: 100%;
    // min-width: 1920px;

    .right-area {
      .project-pad {
        min-width: 899px;
      }
      .tab-header {

        .tab-menu {
          width: auto;
          margin: 0 30px;
        }
      }
    }
  }
}

.project-header {
  width: 100%;
  margin: 20px 0px 10px 0px;
  display: inline-block
}

/* 설계비가산정 */
.cost-page {
  .pad-reduce-btn {
    display: none;
  }
  .right-btn {
    width: 685px;
  }
  .table80 {
    width: 80%;
  }
  .table500 {
    width: 500px;
  }
}

@media screen and (max-width:1024px) {
  .cost-page {
    .pad-reduce-btn {
      display: block;
      text-align: right;
      .reduce-icon {
        font-size: 24px;
        color: #3577f1;
        cursor: pointer;
      }
    }
    .right-btn {
      width: auto;
    }
    .table80 {
      width: 100%;
    }
    .table500 {
      width: 100%;
    }
    &.reduce {
      .left-area {
        width: 18px;
        .reduce-blank {
          display: none;
        }
      }
    }
    .pad-grid-wrap {
      overflow-x: auto;
      .pad-grid {
        width: 1500px;
      }
    }
  }
}