.progress {
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #eff2f7;
  border-radius: 2px;
  &.dark {
    background-color: #374457;
  }
  &.middle {
    height: 10px;
    border-radius: 5px;
    .progress-bar {
      height: 10px;
      border-radius: 5px 0 0 5px;   
    }
  }
  .progress-bar {
    position: absolute;
    height: 5px;
    border-radius: 2px 0 0 2px;
  }
}